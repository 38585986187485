import { McButton, McModal } from '@maersk-global/mds-react-wrapper';
import React from 'react';
import { useIntl } from 'react-intl';

export const CarriersEditModal = ({
  closed,
  onConfirm,
  data,
}: {
  closed: () => void;
  onConfirm: () => void;
  data: unknown;
}) => {
  const { formatMessage } = useIntl();

  return (
    <McModal open closed={closed} dimension={'small'} heading="Are you sure you want to save?">
      <div>The data you provided has some issues:</div>
      <ul>
        {(data as { issues: string[] }).issues.map((issue, i) => (
          <li key={i}>{issue}</li>
        ))}
      </ul>
      <McButton
        slot="secondaryAction"
        appearance="neutral"
        variant="outlined"
        dialogaction="cancel"
        label={formatMessage({ id: 'cancel' })}
      />
      <McButton
        id="confirm"
        slot="primaryAction"
        appearance="primary"
        click={onConfirm}
        label={formatMessage({ id: 'save' })}
      />
    </McModal>
  );
};

import { Maybe } from '../../../common/types';
import { CapacityDataType } from '../../types';
import { isEqual } from 'lodash';

type FormData = {
  moves: number;
  terminalCode: string;
  weeks: string[];
  moveCountStretchPercentage: number;
  moveCountCriticalPercentage: number;
  capacityDataType: CapacityDataType;
  yardCapacity: number;
  reeferPlugs: number;
  imoUnits: number;
  imoUnitsDescription: Maybe<string>;
  oogUnits: number;
  selectedWeeks: string[];
  overwrite: boolean;
};

export type WeeklyValueDtoSOC = {
  moves: number;
  terminalCode: string;
  weeks: string[];
  moveCountStretchPercentage: number;
  moveCountCriticalPercentage: number;
  capacityDataType: CapacityDataType;
  type: 'SOC';
  overwrite: boolean;
};

export type WeeklyValueDtoYard = {
  yardCapacity: number;
  reeferPlugs: number;
  imoUnits: number;
  imoUnitsDescription: Maybe<string>;
  oogUnits: number;
  terminalCode: string;
  weeks: string[];
  capacityDataType: CapacityDataType;
  type: 'YARD';
  overwrite: boolean;
};

export type WeeklyValueDto = WeeklyValueDtoSOC | WeeklyValueDtoYard;

export const createWeeklyValueDto = (formData: FormData, type: 'soc' | 'yard'): WeeklyValueDto => {
  if (type === 'soc') {
    return {
      moves: formData.moves,
      moveCountStretchPercentage: formData.moveCountStretchPercentage,
      moveCountCriticalPercentage: formData.moveCountCriticalPercentage,
      weeks: formData.selectedWeeks,
      capacityDataType: formData.capacityDataType,
      terminalCode: formData.terminalCode,
      type: 'SOC',
      overwrite: formData.overwrite,
    };
  }
  return {
    yardCapacity: formData.yardCapacity,
    reeferPlugs: formData.reeferPlugs,
    imoUnits: formData.imoUnits,
    imoUnitsDescription: formData.imoUnitsDescription,
    oogUnits: formData.oogUnits,
    terminalCode: formData.terminalCode,
    weeks: formData.selectedWeeks,
    capacityDataType: formData.capacityDataType,
    type: 'YARD',
    overwrite: formData.overwrite,
  };
};

export const setUpdatedEntries = (
  entries: (WeeklyValueDtoSOC | WeeklyValueDtoYard)[],
  original: (WeeklyValueDtoSOC | WeeklyValueDtoYard)[],
) => {
  return entries.map((e) => ({
    ...e,
    override: isEqual(
      e,
      original.find((o) => o.capacityDataType === e.capacityDataType),
    )
      ? e.overwrite
      : true,
  }));
};

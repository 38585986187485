import { httpClient } from '../http-client';
import {
  CapacityEntitiesResponse,
  Changelog,
  CreateCapacityRequest,
  FetchCapacityRequest,
  UpdateCapacityRequest,
  UpdateCapacityWithCarriersRequest,
  UpdateWeeklyCapacityWithCarriersRequest,
} from './types';

export const capacityService = () => {
  const client = httpClient();
  const BASE_URL = '/v1/capacity';

  return {
    fetchCapacityForSOC: async ({ terminal }: FetchCapacityRequest): Promise<CapacityEntitiesResponse | null> => {
      const url = `${BASE_URL}/${terminal}?entityType=SOC`;
      try {
        const result = await client.get<CapacityEntitiesResponse>(url);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    fetchCapacityForYard: async ({ terminal }: FetchCapacityRequest): Promise<CapacityEntitiesResponse | null> => {
      const url = `${BASE_URL}/${terminal}?entityType=YARD`;
      try {
        const result = await client.get<CapacityEntitiesResponse>(url);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    updateCapacity: async ({ data }: UpdateCapacityRequest): Promise<CapacityEntitiesResponse | null> => {
      const url = `${BASE_URL}`;
      try {
        const result = await client.post<CapacityEntitiesResponse>(url, data);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    updateCapacityWithCarriers: async (
      data: UpdateWeeklyCapacityWithCarriersRequest,
    ): Promise<CapacityEntitiesResponse | null> => {
      const url = `${BASE_URL}`;
      try {
        const result = await client.post<CapacityEntitiesResponse>(url, data);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    createTemplate: async ({ data }: CreateCapacityRequest): Promise<CapacityEntitiesResponse | null> => {
      const url = `${BASE_URL}/template/create`;
      try {
        const result = await client.post<CapacityEntitiesResponse>(url, data);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    updateTemplate: async ({ data }: UpdateCapacityRequest): Promise<CapacityEntitiesResponse | null> => {
      const url = `${BASE_URL}/template`;
      try {
        const result = await client.post<CapacityEntitiesResponse>(url, data);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    updateTemplateWithCarriers: async (
      data: UpdateCapacityWithCarriersRequest,
    ): Promise<CapacityEntitiesResponse | null> => {
      const url = `${BASE_URL}/template`;
      try {
        const result = await client.post<CapacityEntitiesResponse>(url, data);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    deleteTemplate: async ({ id }: { id: string }): Promise<{} | null> => {
      const url = `${BASE_URL}/template/${id}/delete`;
      try {
        const result = await client.post<CapacityEntitiesResponse>(url, {});
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
    getChangelog: async ({ id }: { id: string }): Promise<Changelog[] | null> => {
      const url = `${BASE_URL}/${id}/logs?capacityDataType=TOTAL`;
      try {
        const result = await client.get<Changelog[]>(url);
        return result.data;
      } catch (e) {
        logger(`Error calling API: ${e}`);
        return null;
      }
    },
  };
};

const logger = (message: string): void => console.error(`[Capacity Service] ${message}`);

enum Carriers {
  Maersk = 'MAERSK',
  Hapaglloyd = 'HAPAGLLOYD',
  MaerskGem = 'GEMINI_MAERSK',
  HapaglloydGem = 'GEMINI_HAPAGLLOYD',
  Others = 'OTHERS',
}

export const terminals = [
  {
    name: 'Algeciras',
    code: 'ESALR',
    carriers: [Carriers.Maersk, Carriers.MaerskGem, Carriers.Hapaglloyd, Carriers.HapaglloydGem, Carriers.Others],
    carriersAvailable: true,
  },
  {
    name: 'Rotterdam  Maasvlakte II',
    code: 'NLROT',
    carriers: [Carriers.Maersk, Carriers.MaerskGem, Carriers.Hapaglloyd, Carriers.HapaglloydGem, Carriers.Others],
    carriersAvailable: true,
  },
  {
    name: 'Tanjung Pelepas',
    code: 'MYTPP',
    carriers: [Carriers.Maersk, Carriers.MaerskGem, Carriers.Hapaglloyd, Carriers.HapaglloydGem, Carriers.Others],
    carriersAvailable: true,
  },
  {
    name: 'Suez Canal Container Terminal',
    code: 'EGPSD',
    carriers: [Carriers.Maersk, Carriers.MaerskGem, Carriers.Hapaglloyd, Carriers.HapaglloydGem, Carriers.Others],
    carriersAvailable: true,
  },
  {
    name: 'Salalah',
    code: 'OMSLV',
    carriers: [Carriers.Maersk, Carriers.MaerskGem, Carriers.Hapaglloyd, Carriers.HapaglloydGem, Carriers.Others],
    carriersAvailable: true,
  },
  {
    name: 'Tangier Med - TC1',
    code: 'MAPTMTM',
    carriers: [Carriers.Maersk, Carriers.MaerskGem, Carriers.Hapaglloyd, Carriers.HapaglloydGem, Carriers.Others],
    carriersAvailable: true,
  },
  {
    name: 'Tangier Med - TM2',
    code: 'MAPTM02',
    carriers: [Carriers.Maersk, Carriers.MaerskGem, Carriers.Hapaglloyd, Carriers.HapaglloydGem, Carriers.Others],
    carriersAvailable: true,
  },
  {
    name: 'Lazaro Cardenas',
    code: 'MXLZC',
    carriers: [Carriers.Maersk, Carriers.MaerskGem, Carriers.Hapaglloyd, Carriers.HapaglloydGem, Carriers.Others],
    carriersAvailable: true,
  },
  {
    name: 'Bremerhaven',
    code: 'DEBRVTM',
    carriers: [Carriers.Maersk, Carriers.MaerskGem, Carriers.Hapaglloyd, Carriers.HapaglloydGem, Carriers.Others],
    carriersAvailable: true,
  },
];

export const getTerminalByCode = (code: string) => terminals.find((terminal) => terminal.code === code);

import { enqueueSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useFetchData } from '../../common/hooks/useFetchData';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  capacityEntitiesAtomSOC,
  capacityTemplatesAtomSOC,
  capacityEntitiesAtomYard,
  capacityTemplatesAtomYard,
  occAtom,
  terminalCodeAtom,
} from '../../../state';
import { DependencyContainer } from '../../../http/DependencyContainer';
import { capacityService } from '../../common/service/capacity/capacity-service';
import {
  CreateCapacityRequest,
  UpdateCapacityRequest,
  UpdateCapacityWithCarriersRequest,
  UpdateWeeklyCapacityWithCarriersRequest,
} from '../../common/service/capacity/types';
import { occService } from '../../common/service/occ/occ-service';
import { UpdateOCCRequest } from '../../common/service/occ/types';
import { CapacityEntitiesResponse } from '../types';
import { useEffect, useState } from 'react';

const { octwService } = new DependencyContainer();

const useCapacityData = () => {
  const terminalCode = useRecoilValue(terminalCodeAtom);
  const [capacityEntitiesSOC, setCapacityEntitiesSOC] = useRecoilState(capacityEntitiesAtomSOC);
  const [capacityTemplatesSOC, setCapacityTemplatesSOC] = useRecoilState(capacityTemplatesAtomSOC);
  const [capacityEntitiesYard, setCapacityEntitiesYard] = useRecoilState(capacityEntitiesAtomYard);
  const [capacityTemplatesYard, setCapacityTemplatesYard] = useRecoilState(capacityTemplatesAtomYard);
  const [occ, setOcc] = useRecoilState(occAtom);
  const [loading, setLoading] = useState(false);
  const [loadingOCC, setLoadingOCC] = useState(false);
  const { formatMessage } = useIntl();

  const fetchOCC = (terminal: string) => {
    setLoadingOCC(true);
    return occService()
      .fetchOCC({ terminal })
      .then((result) => {
        if (result) {
          setOcc(result);
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
        setLoadingOCC(false);
      });
  };

  const updateOCC = (data: UpdateOCCRequest['data']) => {
    return occService()
      .updateOCC({ data })
      .then((result) => {
        if (result) {
          fetchOCC(terminalCode);
          enqueueSnackbar('Value updated successfully', { variant: 'success' });
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
      });
  };

  const fetchCapacityForSOC = (terminal: string) => {
    setLoading(true);
    return capacityService()
      .fetchCapacityForSOC({ terminal })
      .then((result) => {
        if (result) {
          setCapacityEntitiesSOC(result.capacityEntities);
          setCapacityTemplatesSOC(result.capacityTemplateEntities);
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
        setLoading(false);
      });
  };

  const fetchCapacityForYard = (terminal: string) => {
    setLoading(true);
    return capacityService()
      .fetchCapacityForYard({ terminal })
      .then((result) => {
        if (result) {
          setCapacityEntitiesYard(result.capacityEntities);
          setCapacityTemplatesYard(result.capacityTemplateEntities);
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
        setLoading(false);
      });
  };

  const fetchCapacity = async (terminal: string) => {
    await fetchCapacityForSOC(terminal);
    await fetchCapacityForYard(terminal);
  };

  const updateCapacity = async (data: UpdateCapacityRequest['data']) => {
    return capacityService()
      .updateCapacity({ data })
      .then((result) => {
        if (result) {
          // TODO: update the state w/o fetching the data anew
          fetchCapacity(terminalCode);
          enqueueSnackbar('Weekly values updated', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
      });
  };

  const updateCapacityWithCarriers = async (data: UpdateWeeklyCapacityWithCarriersRequest) => {
    return capacityService()
      .updateCapacityWithCarriers(data)
      .then((result) => {
        if (result) {
          // TODO: update the state w/o fetching the data anew
          fetchCapacity(terminalCode);
          enqueueSnackbar('Weekly values updated', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
      });
  };

  const updateTemplate = async (data: UpdateCapacityRequest['data']) => {
    return capacityService()
      .updateTemplate({ data })
      .then((result) => {
        if (result) {
          // TODO: update the state w/o fetching the data anew
          fetchCapacity(terminalCode);
          enqueueSnackbar('Template updated', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
      });
  };

  const updateTemplateWithCarriers = async (data: UpdateCapacityWithCarriersRequest) => {
    return capacityService()
      .updateTemplateWithCarriers(data)
      .then((result) => {
        if (result) {
          // TODO: update the state w/o fetching the data anew
          fetchCapacity(terminalCode);
          enqueueSnackbar('Template updated', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
      });
  };

  const deleteTemplate = async (id: string) => {
    return capacityService()
      .deleteTemplate({ id })
      .then((result) => {
        if (result !== null) {
          // TODO: update the state w/o fetching the data anew
          fetchCapacity(terminalCode);
          enqueueSnackbar('Template deleted successfully', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
      });
  };

  const createTemplate = async (data: CreateCapacityRequest['data']) => {
    return capacityService()
      .createTemplate({ data })
      .then((result) => {
        if (result) {
          // TODO: update the state w/o fetching the data anew
          fetchCapacity(terminalCode);
          enqueueSnackbar('Template created successfully', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
        }
      });
  };

  // TODO: remove this (only carriers usiing it)
  const [loadingCapacity, , , capacityFetcher] = useFetchData<CapacityEntitiesResponse, any>(
    async () => {
      if (!terminalCode) {
        return;
      }
      return octwService.getCapacityForTerminal(terminalCode);
    },
    (response: CapacityEntitiesResponse) => {
      if (response.capacityEntities) {
        //setCapacityEntities(response.capacityEntities);
      }
      if (response.capacityTemplateEntities) {
        //setCapacityTemplates(response.capacityTemplateEntities);
      }
    },
    [terminalCode],
    () => !terminalCode,
  );

  useEffect(() => {
    capacityFetcher();
  }, [terminalCode, capacityFetcher]);

  return {
    loading,
    loadingCapacity,
    loadingOCC,
    capacityTemplatesSOC,
    capacityEntitiesSOC,
    capacityTemplatesYard,
    capacityEntitiesYard,
    occ,
    capacityFetcher,
    setCapacityEntitiesSOC,
    setCapacityEntitiesYard,
    fetchCapacity,
    fetchCapacityForSOC,
    fetchCapacityForYard,
    updateCapacity,
    updateTemplate,
    deleteTemplate,
    createTemplate,
    fetchOCC,
    updateOCC,
    updateTemplateWithCarriers,
    updateCapacityWithCarriers,
  };
};

export default useCapacityData;

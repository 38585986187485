import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  McLoadingIndicator,
  McNotification,
  McOption,
  McSelect,
  McTabBar,
  McTab,
} from '@maersk-global/mds-react-wrapper';
import { AppRoutes } from '../../../config/routes';
import { useRedirectToRoute } from '../../common/hooks/useRedirectToRoute';
import OCCTab from './occ/OCCTab';
import SOCTab from './soc/SOCTab';
import YardTab from './yard/YardTab';
import useAuth from '../../common/hooks/useAuth';
import { getTerminalByCode, terminals } from '../../common/helpers/terminals';
import useCapacityData from '../hooks/useCapacityData';
import { useRecoilState } from 'recoil';
import { terminalCodeAtom } from '../../../state';

const CapacityIdContainer: React.FC = () => {
  const { userData } = useAuth();
  const { id } = useParams<{ id: string }>();
  const redirectToRoute = useRedirectToRoute();
  const [selectedTerminal, setSelectedTerminal] = useRecoilState(terminalCodeAtom);
  const [error] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const { loading, loadingOCC, fetchCapacity, fetchOCC } = useCapacityData();

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (!id) {
      return;
    }
    setSelectedTerminal(id);
  }, [id, setSelectedTerminal]);

  const handleSelectChange = async (selectedCode: string) => {
    if (!selectedCode) {
      return;
    }
    setSelectedTerminal(selectedCode);
    redirectToRoute(AppRoutes.CapacityId, { id: selectedCode }, { replace: true });
  };

  useEffect(() => {
    if (selectedTerminal) {
      fetchCapacity(selectedTerminal);
      fetchOCC(selectedTerminal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- by design
  }, [selectedTerminal]);

  return (
    <div className="CapacityIdContainer">
      <div className="CapacityIdContainer__dropdown-wrapper">
        <FormattedMessage id="updateRuleDrawerFormTerminal" />
        <div className="CapacityIdContainer__dropdown-wrapper__content">
          <McSelect
            className="CapacityIdContainer__dropdown"
            hiddenlabel
            placeholder="Select terminal"
            value={selectedTerminal}
            optionselected={(e) => {
              handleSelectChange((e.target as HTMLInputElement).value);
            }}
            name="terminal"
          >
            {userData?.terminalCodes &&
              terminals
                .filter((terminal) => userData.terminalCodes.includes(terminal.code))
                .map((terminal) => (
                  <McOption key={terminal.code} value={terminal.code}>
                    {terminal.name}
                  </McOption>
                ))}
          </McSelect>
        </div>
      </div>

      <h1 className="CapacityIdContainer__heading">
        {selectedTerminal
          ? `${getTerminalByCode(selectedTerminal)?.name} (${getTerminalByCode(selectedTerminal)?.code})`
          : ''}
      </h1>

      {error && (
        <McNotification appearance="error" heading={formatMessage({ id: 'error' })}>
          <FormattedMessage id="genericErrorMessage" />
        </McNotification>
      )}
      <McTabBar tabchange={(e) => setActiveTab(e.detail)}>
        <McTab slot="tab" label="SOC" />
        <div slot="panel">
          {loading ? (
            <McLoadingIndicator label={formatMessage({ id: 'fetchingData' })} />
          ) : (
            selectedTerminal && <SOCTab selectedTerminal={selectedTerminal} isActive={activeTab === 0} />
          )}
        </div>

        <McTab slot="tab" label="Yard" />
        <div slot="panel">
          {loading ? (
            <McLoadingIndicator label={formatMessage({ id: 'fetchingData' })} />
          ) : (
            selectedTerminal && <YardTab selectedTerminal={selectedTerminal} isActive={activeTab === 1} />
          )}
        </div>

        <McTab slot="tab" label="OCL" />
        <div slot="panel">
          {loadingOCC ? (
            <McLoadingIndicator label={formatMessage({ id: 'fetchingData' })} />
          ) : (
            selectedTerminal && <OCCTab selectedTerminal={selectedTerminal} />
          )}
        </div>
      </McTabBar>
    </div>
  );
};

export default CapacityIdContainer;

import { createContext, useState, ReactNode } from 'react';
import { ResetCarrierModal } from './ResetCarrierModal';
import { ResetWeeklyValuesModal } from './ResetWeeklyValuesModal';
import { CarriersEditModal } from './CarriersEditModal';

export enum ModalType {
  ResetCarrier = 'ResetCarrier',
  ResetWeekly = 'ResetWeekly',
  CarriersEdit = 'CarriersEdit',
}

type ModalProviderProps = {
  showModal: (type: ModalType, onConfirm: Function, data?: unknown) => void;
};

const initialState = {
  showModal: () => {},
};

export const ModalContext = createContext<ModalProviderProps>(initialState);

export function ModalProvider({ children }: { children: ReactNode }) {
  const [activeModal, setActiveModal] = useState<ModalType>();
  const [activeCallback, setActiveCallback] = useState<Function>();
  const [activeData, setActiveData] = useState<unknown>();

  const showModal = (type: ModalType, onConfirm: Function, data?: unknown) => {
    setActiveModal(type);
    setActiveCallback(onConfirm);
    setActiveData(data);
  };

  const clear = () => {
    setActiveModal(undefined);
    setActiveCallback(undefined);
  };

  const renderModal = () => {
    const props = {
      closed: clear,
      onConfirm: () => {
        activeCallback?.();
        clear();
      },
      data: activeData,
    };
    switch (activeModal) {
      case ModalType.ResetCarrier:
        return <ResetCarrierModal {...props} />;
      case ModalType.ResetWeekly:
        return <ResetWeeklyValuesModal {...props} />;
      case ModalType.CarriersEdit:
        return <CarriersEditModal {...props} />;
      default:
        return null;
    }
  };

  return (
    <ModalContext.Provider value={{ showModal }}>
      <>
        {children}
        {renderModal()}
      </>
    </ModalContext.Provider>
  );
}

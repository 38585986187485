import { McButton, McTable } from '@maersk-global/mds-react-wrapper';
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { isCarriersAvailableForTerminal } from '../../../common/helpers/carriers';
import { modifyDateWeek } from '../../../common/helpers/modify-date';
import { formattedNumberToInt } from '../../../common/helpers/utils';
import TerminalCarriersRenderer from '../../components/TerminalCarriersRenderer/TerminalCarriersRenderer';
import { defaultDataMapperSOC, weeklyDataMapperSOC } from '../../config/mappers';
import useCapacityData from '../../hooks/useCapacityData';
import AddDefaultValuesDrawer from '../AddDefaultValuesDrawer/AddDefaultValuesDrawer';
import EditDefaultValuesDrawer from '../EditDefaultValuesDrawer/EditDefaultValuesDrawer';
import EditWeeklyValuesDrawer from '../EditWeeklyValuesDrawer/EditWeeklyValuesDrawer';
import { defaultValuesTableColumns, weeklyValuesTableColumns } from './const';

type SOCTabProps = {
  selectedTerminal: string;
  isActive: boolean;
};

const SOCTab: React.FC<SOCTabProps> = (props) => {
  const { selectedTerminal, isActive } = props;
  const defaultTableRef = useRef(null);
  const weeklyTableRef = useRef(null);
  const { formatMessage } = useIntl();
  const { capacityTemplatesSOC, capacityEntitiesSOC } = useCapacityData();
  const [selectedDefaultRow, setSelectedDefaultRow] = useState(null);
  const [selectedWeeklyData, setSelectedWeeklyData] = useState(null);
  const [selectedWeeklyRow, setSelectedWeeklyRow] = useState([]);
  const [selectedWeeklyRows, setSelectedWeeklyRows] = useState([]);
  const [selectedAddDefaultValues, setSelectedAddDefaultValues] = useState(null);

  const onDefaultRowClick = (e) => {
    const row = e.detail.rowData;
    setSelectedDefaultRow(row);
  };

  const onWeeklySelectChange = (e) => {
    setSelectedWeeklyRows(e.detail);
  };

  const onWeeklyRowClick = (e) => {
    const row = e.detail.rowData;

    // Check if multiple rows selected, if all numbers same then set it, else set all to 0 0 0
    setSelectedWeeklyData({
      // @ts-ignore
      moves: row.moveCount,
      moveCountStretchPercentage: parseInt(row.moveCountStretchPercentage),
      moveCountCriticalPercentage: parseInt(row.moveCountCriticalPercentage),
      capacityDataType: row.capacityDataType,
      id: row.id,
      entries: row.entries,
    });

    // @ts-ignore
    setSelectedWeeklyRow([row.date]);
  };

  const handleAddClick = () => {
    // @ts-ignore
    const lastEntry = capacityTemplatesSOC[capacityTemplatesSOC.length - 1];
    const latestData = {
      // @ts-ignore
      ...lastEntry.entries.find((e) => e.capacityDataType === 'TOTAL'),
      // @ts-ignore
      startDate: modifyDateWeek(lastEntry.startDate.split('T')[0], 'add'),
      // @ts-ignore
      previousStartDate: lastEntry.startDate.split('T')[0],
      nextStartDate: null,
    };
    // @ts-ignore
    setSelectedAddDefaultValues(latestData);
  };

  const handleMultipleEditClick = () => {
    setSelectedWeeklyData({
      // @ts-ignore
      moves: formattedNumberToInt(selectedWeeklyRows[0].moveCount),
      // @ts-ignore
      moveCountStretchPercentage: parseInt(selectedWeeklyRows[0].moveCountStretchPercentage),
      // @ts-ignore
      moveCountCriticalPercentage: parseInt(selectedWeeklyRows[0].moveCountCriticalPercentage),
      // @ts-ignore
      capacityDataType: selectedWeeklyRows[0].capacityDataType,
      // @ts-ignore
      entries: selectedWeeklyRows[0].entries,
    });
    setSelectedWeeklyRow(
      // @ts-ignore
      selectedWeeklyRows.map((row) => row.date),
    );
  };

  return (
    <div className="CapacityTab">
      <div>
        <h2>{formatMessage({ id: 'soc' })}</h2>

        <div className="CapacityTab__default-title">
          <h3>{formatMessage({ id: 'defaultValues' })}</h3>
          <McButton
            label="Add new start period"
            appearance="primary"
            icon="plus"
            variant="outlined"
            click={handleAddClick}
          />
        </div>
        <McTable
          ref={defaultTableRef}
          columns={defaultValuesTableColumns}
          expand={isCarriersAvailableForTerminal(selectedTerminal)}
          expandpadding="none"
          datakey="id"
          data={capacityTemplatesSOC.map(defaultDataMapperSOC(capacityTemplatesSOC, selectedTerminal))}
          rowclick={onDefaultRowClick}
        >
          <TerminalCarriersRenderer
            type="default"
            columns={defaultValuesTableColumns}
            ref={defaultTableRef}
            data={capacityTemplatesSOC.map(defaultDataMapperSOC(capacityTemplatesSOC, selectedTerminal))}
            dataKey="id"
            isActive={isActive}
          />
        </McTable>

        <h3>{formatMessage({ id: 'weeklyValues' })}</h3>
        {!!selectedWeeklyRows.length && (
          <div className="CapacityTab__selected-rows-banner">
            <span>{formatMessage({ id: 'selectedWeeks' }, { count: selectedWeeklyRows.length })}</span>
            <McButton
              label="Edit"
              icon="pencil"
              appearance="secondary"
              variant="outlined"
              padding="compact"
              click={handleMultipleEditClick}
            />
          </div>
        )}

        <McTable
          // @ts-ignore
          rowclick={onWeeklyRowClick}
          selectchange={onWeeklySelectChange}
          select={true}
          expand={isCarriersAvailableForTerminal(selectedTerminal)}
          expandpadding="none"
          datakey="date"
          ref={weeklyTableRef}
          columns={weeklyValuesTableColumns}
          data={capacityEntitiesSOC.map((e) => weeklyDataMapperSOC(e, selectedTerminal))}
        >
          <TerminalCarriersRenderer
            type="weekly"
            columns={weeklyValuesTableColumns}
            ref={weeklyTableRef}
            data={capacityEntitiesSOC}
            dataKey="date"
            isActive={isActive}
          />
        </McTable>
      </div>

      {selectedDefaultRow && (
        <EditDefaultValuesDrawer
          type="soc"
          // @ts-ignore
          defaultValues={selectedDefaultRow}
          selectedTerminal={selectedTerminal}
          // @ts-ignore
          id={selectedDefaultRow?.id}
          open={Boolean(selectedDefaultRow)}
          onSuccess={() => {}}
          setOpen={() => setSelectedDefaultRow(null)}
        />
      )}

      {selectedWeeklyData && (
        <EditWeeklyValuesDrawer
          type="soc"
          // @ts-ignore
          defaultValues={selectedWeeklyData}
          selectedTerminal={selectedTerminal}
          open={selectedWeeklyRow.length > 0}
          selectedWeeks={selectedWeeklyRow}
          onSuccess={() => {
            setSelectedWeeklyRows([]);
          }}
          setOpen={() => setSelectedWeeklyRow([])}
        />
      )}

      {selectedAddDefaultValues && (
        <AddDefaultValuesDrawer
          type="soc"
          // @ts-ignore
          defaultValues={selectedAddDefaultValues}
          selectedTerminal={selectedTerminal}
          // @ts-ignore
          id={selectedDefaultRow?.id}
          open={Boolean(selectedAddDefaultValues)}
          setOpen={() => setSelectedAddDefaultValues(null)}
        />
      )}
    </div>
  );
};

export default SOCTab;

import { TagAppearance } from '@maersk-global/mds-components-core/types';
import { McButton, McInput, McTag } from '@maersk-global/mds-react-wrapper';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { useRecoilState } from 'recoil';
import { carriersUnderEditAtom, CarrierUpdateDto } from '../../../state';
import FormattedMessage from '../../common/FormattedMessage';
import { pushOrUpdateCollection } from '../../common/helpers/collections';
import { useIntl } from '../../common/hooks/useIntl';
import { ModalContext, ModalType } from '../../common/modals/ModalProvider';
import { carrierService } from '../../common/service/carrier/carrier-service';
import { Translation } from '../../common/types';
import { defaultColumns, weeklyColumns } from '../config/capacity-columns';
import { CapacityDataType, CapacityEntity, CapacityEntityEntry } from '../types';
import useCapacityData from './useCapacityData';
import { setUpdatedEntries } from '../containers/dto/WeeklyValueDto';

type CarrierColumnsDefinition = {
  fieldName: string;
  render?: (carrier: CapacityEntityEntry, editMode: boolean) => any;
};

export type ColumnsDefinitionType = 'default' | 'weekly';

const useCarrierOperations = () => {
  const [carriersUnderEdit, setCarriersUnderEdit] = useRecoilState(carriersUnderEditAtom);
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const {
    capacityEntitiesSOC,
    capacityEntitiesYard,
    setCapacityEntitiesSOC,
    setCapacityEntitiesYard,
    updateTemplateWithCarriers,
    updateCapacityWithCarriers,
  } = useCapacityData();
  const { showModal } = useContext(ModalContext);

  let defaultCarrierColumnsDefinition = defaultColumns.map((defaultColumn) => ({
    fieldName: defaultColumn.id,
  }));
  let weeklyCarrierColumnsDefinition = weeklyColumns.map((weeklyColumn) => ({
    fieldName: weeklyColumn.id,
  }));

  const getCarrierUnderEditIndex = (carrierId: string, item: CapacityEntity, type: ColumnsDefinitionType) => {
    return carriersUnderEdit.findIndex((cue) => {
      return item.id === cue.entryId;
    });
  };

  const isCarrierUnderEdit = (carrierId: string, item: CapacityEntity, type: ColumnsDefinitionType): boolean => {
    const foundIndex = getCarrierUnderEditIndex(carrierId, item, type);
    return foundIndex !== -1;
  };

  const updateDtoProperty = (carrier: CapacityEntityEntry, property: keyof CarrierUpdateDto, value: any) => {
    setCarriersUnderEdit((current) => {
      const newEntries = current[0].dto.map((e) => {
        if (e.capacityDataType === carrier.capacityDataType) {
          return { ...e, [property]: value };
        } else {
          return e;
        }
      });
      return [
        {
          ...current[0],
          dto: newEntries,
        },
      ];
    });
  };

  // TODO - refactor
  const updateDto = (id: string, value: CapacityEntityEntry[]) => {
    // @ts-ignore
    const isSOC = value[0].type === 'SOC';
    const dtoToUpdateIndex = (isSOC ? capacityEntitiesSOC : capacityEntitiesYard).findIndex(
      (entity) => entity.id === id,
    );
    const dtoToUpdate = { ...(isSOC ? capacityEntitiesSOC : capacityEntitiesYard)[dtoToUpdateIndex] };
    if (dtoToUpdate) {
      // @ts-ignore
      dtoToUpdate.entries = value;
      const newCapacityEntities = [...(isSOC ? capacityEntitiesSOC : capacityEntitiesYard)];
      newCapacityEntities[dtoToUpdateIndex] = dtoToUpdate;
      isSOC ? setCapacityEntitiesSOC(newCapacityEntities) : setCapacityEntitiesYard(newCapacityEntities);
    }
  };

  const getDto = (): CarrierUpdateDto => {
    if (!carriersUnderEdit || carriersUnderEdit?.length === 0) {
      return null;
    }
    return carriersUnderEdit[0].dto;
  };

  const getDtoProperty = (carrier: CapacityEntityEntry, property: keyof CarrierUpdateDto) => {
    const dto = getDto();
    if (!dto) {
      return '';
    }
    return dto.find((e) => e.capacityDataType === carrier.capacityDataType)?.[property] || '';
  };

  const onEditClick = (
    carrier: CapacityEntityEntry,
    columnsDefinitionType: ColumnsDefinitionType,
    item: CapacityEntity,
  ) => {
    setCarriersUnderEdit([
      {
        entryId: item.id,
        //@ts-ignore
        week: item.date,
        //@ts-ignore
        terminalCode: item.terminalCode,
        //@ts-ignore
        terminalCodeAndStartDate: item.id,
        startDate: item.startDate,
        type: columnsDefinitionType,
        dto: item.entries,
        originalEntries: [...item.entries],
      },
    ]);
  };

  const onCancelEditClick = () => {
    setCarriersUnderEdit([]);
  };

  const onSaveClick = async (
    carrier: CapacityEntityEntry,
    columnsDefinitionType: ColumnsDefinitionType,
    item: CapacityEntity,
  ) => {
    const data = carriersUnderEdit[0];
    const totalMoves = data.dto.find((e) => e.capacityDataType === CapacityDataType.Total)?.moves || 0;
    const issues: string[] = [];
    if (
      Math.abs(
        data.dto
          .filter((e) => e.capacityDataType !== CapacityDataType.Total)
          .reduce((acc, current) => (acc += current.moves), 0) - totalMoves,
      ) >
      totalMoves * 0.015
    ) {
      issues.push('Sum of Movecount for operators differs from Total Movecount');
    }

    const submit = async () => {
      try {
        if (data.type === 'default') {
          // Update default data
          await updateTemplateWithCarriers({
            entries: data.dto,
            entityType: data.dto.find((e) => e.capacityDataType === CapacityDataType.Total)?.type,
            // @ts-ignore
            id: data.entryId,
            // @ts-ignore
            startDate: data.startDate,
            //@ts-ignore
            terminalCode: data.terminalCode,
          });
        } else {
          // Update weekly data
          await updateCapacityWithCarriers({
            // @ts-ignore
            entries: setUpdatedEntries(data.dto, data.originalEntries),
            entityType: data.dto.find((e) => e.capacityDataType === CapacityDataType.Total)?.type,
            // @ts-ignore
            terminalCode: data.terminalCode,
            // @ts-ignore
            weeks: [data.week],
          });
        }
        setCarriersUnderEdit([]);
      } catch (e) {}
    };

    if (issues.length > 0) {
      showModal(ModalType.CarriersEdit, () => () => submit(), { issues });
    } else {
      submit();
    }
  };

  const onResetClick = (
    carrier: CapacityEntityEntry,
    columnsDefinitionType: ColumnsDefinitionType,
    item: CapacityEntity,
  ) => {
    if (columnsDefinitionType === 'weekly') {
      carrierService()
        .resetCapacity({ rowId: item.id, type: carrier.capacityDataType })
        .then((result) => {
          if (result) {
            // @ts-ignore
            updateDto(item.id, result.entries);
          } else {
            enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
          }
        });
    }
  };

  const onInputField = (carrier: CapacityEntityEntry, value: any, fieldName: keyof CapacityEntityEntry) => {
    updateDtoProperty(carrier, fieldName, parseInt(value));
    return value;
  };

  const onInputPercentage = (carrier: CapacityEntityEntry, totalMoves: number, value: any) => {
    const moves = parseInt(value) ? Math.round((parseInt(value) / 100) * totalMoves) : 0;
    updateDtoProperty(carrier, 'moves', moves);
    return value;
  };

  const calculateMovesPercentage = (totalMoves: number, moves: number) => Math.round((moves / totalMoves) * 100);

  const withClassicEditInput = (
    carrier: CapacityEntityEntry,
    editMode: boolean,
    fieldName: keyof CapacityEntityEntry,
  ) => {
    if (!editMode) {
      return `${carrier[fieldName]}`;
    }
    const value = getDtoProperty(carrier, fieldName);
    return (
      <McInput
        hiddenlabel
        name={fieldName}
        value={value}
        input={(e: any) => onInputField(carrier, e.target.value, fieldName)}
        fit="small"
      />
    );
  };

  const withMoveCountInput = (
    carrier: CapacityEntityEntry,
    editMode: boolean,
    fieldName: keyof CapacityEntityEntry,
  ) => {
    if (!editMode) {
      return `${carrier.moves} (${calculateMovesPercentage(carrier.totalMoves || 0, carrier.moves)}%)`;
    }
    return (
      <div style={{ display: 'flex', gap: '8px' }}>
        <McInput
          hiddenlabel
          name="moveCount"
          value={getDtoProperty(carrier, fieldName)}
          input={(e: any) => onInputField(carrier, e.target.value, fieldName)}
          fit="small"
          type="number"
          min={0}
        />
        <McInput
          hiddenlabel
          name="moveCountPercentage"
          value={calculateMovesPercentage(carrier.totalMoves || 0, getDtoProperty(carrier, fieldName))}
          input={(e: any) => onInputPercentage(carrier, carrier.totalMoves || 0, e.target.value)}
          suffix="%"
          fit="small"
          type="number"
          step="1"
        />
      </div>
    );
  };

  const withThresholdInput = (
    carrier: CapacityEntityEntry,
    editMode: boolean,
    fieldName: keyof CapacityEntityEntry,
  ) => {
    if (!editMode) {
      return `${carrier[fieldName]}%`;
    }
    return (
      <McInput
        key={fieldName}
        hiddenlabel
        name={fieldName}
        value={getDtoProperty(carrier, fieldName)}
        suffix="%"
        min={0}
        max={100}
        input={(e: any) => onInputField(carrier, e.target.value, fieldName)}
        fit="small"
      />
    );
  };

  const renderStatus = (isOverwritten: boolean) => {
    const appearance: TagAppearance = isOverwritten ? 'warning' : 'neutral';
    const messageId: keyof Translation = isOverwritten ? 'statusAdjusted' : 'statusDefault';
    return (
      <McTag appearance={appearance} fit="small">
        <FormattedMessage id={messageId} />
      </McTag>
    );
  };

  const getCarrierColumnsDefinitions = (
    columnsDefinitionType: ColumnsDefinitionType,
    item: CapacityEntity,
    isOverwritten: boolean,
  ): CarrierColumnsDefinition[] => {
    let collection =
      columnsDefinitionType === 'default' ? defaultCarrierColumnsDefinition : weeklyCarrierColumnsDefinition;

    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'actions',
        render: (carrier: CapacityEntityEntry, editMode: boolean, showButtons?: boolean) => {
          const withOperationsArgs = (
            carrier: CapacityEntityEntry,
            type: ColumnsDefinitionType,
            item: CapacityEntity,
          ) => {
            return (func: CallableFunction) => {
              func(carrier, type, item);
            };
          };
          const operation = withOperationsArgs(carrier, columnsDefinitionType, item);
          const commonStyles = {
            display: 'flex',
            justifyContent: 'flex-end',
          };
          if (editMode && !showButtons) {
            return null;
          }
          return (
            <>
              {editMode ? (
                <div style={commonStyles}>
                  <span className="CarrierActions__cancel">
                    <McButton
                      fit="small"
                      click={() => operation(onCancelEditClick)}
                      variant="plain"
                      appearance="neutral"
                      icon="times"
                    >
                      <FormattedMessage id="cancel" />
                    </McButton>
                  </span>
                  <span className="CarrierActions__save">
                    <McButton
                      fit="small"
                      click={() => operation(onSaveClick)}
                      variant="plain"
                      appearance="neutral"
                      icon="check"
                    >
                      <FormattedMessage id="save" />
                    </McButton>
                  </span>
                </div>
              ) : (
                <div style={commonStyles}>
                  {columnsDefinitionType === 'weekly' && isOverwritten && (
                    <McButton
                      fit="small"
                      click={() => showModal(ModalType.ResetCarrier, () => () => operation(onResetClick))}
                      variant="plain"
                      icon="arrow-anti-clockwise"
                      appearance="neutral"
                      disabled={!isOverwritten}
                    >
                      <FormattedMessage id="statusDefault" />
                    </McButton>
                  )}
                  <McButton
                    fit="small"
                    click={() => operation(onEditClick)}
                    variant="plain"
                    appearance="neutral"
                    icon="pencil"
                  >
                    <FormattedMessage id="edit" />
                  </McButton>
                </div>
              )}
            </>
          );
        },
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'date',
        render: (carrier: CapacityEntityEntry, editMode: boolean) => null,
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'moveCount',
        render: (carrier: CapacityEntityEntry, editMode: boolean) => withMoveCountInput(carrier, editMode, 'moves'),
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'moveCountStretch',
        render: (carrier: CapacityEntityEntry, editMode: boolean) => null,
        // withThresholdInput(carrier, editMode, 'moveCountStretchPercentage'),
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'moveCountCritical',
        render: (carrier: CapacityEntityEntry, editMode: boolean) => null,
        // withThresholdInput(carrier, editMode, 'moveCountCriticalPercentage'),
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'status',
        render: (carrier: CapacityEntityEntry, editMode: boolean) => renderStatus(Boolean(carrier?.overwrite)),
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'yardCapacity',
        render: (carrier: CapacityEntityEntry, editMode: boolean) =>
          withClassicEditInput(carrier, editMode, 'yardCapacity'),
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'reeferPlugs',
        render: (carrier: CapacityEntityEntry, editMode: boolean) =>
          withClassicEditInput(carrier, editMode, 'reeferPlugs'),
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'imoUnits',
        render: (carrier: CapacityEntityEntry, editMode: boolean) =>
          withClassicEditInput(carrier, editMode, 'imoUnits'),
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'oogUnits',
        render: (carrier: CapacityEntityEntry, editMode: boolean) =>
          withClassicEditInput(carrier, editMode, 'oogUnits'),
      },
      'fieldName',
    );
    return collection;
  };

  return { getCarrierColumnsDefinitions, isCarrierUnderEdit, renderStatus, updateDto, carriersUnderEdit };
};

export default useCarrierOperations;
